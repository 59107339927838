import forge, { Request, Response, Middleware } from "mappersmith";
import EncodeJson from "mappersmith/middleware/encode-json";
import Retry from "mappersmith/middleware/retry/v2";
import { Auth } from "@aws-amplify/auth";
import { setErrorHandler } from "mappersmith/middleware/global-error-handler";

const sharedHeaders = {
	"Content-Type": "application/json",
	Accept: "application/json",
};

const retryConfigs = {
	headerRetryCount: "X-Mappersmith-Retry-Count",
	headerRetryTime: "X-Mappersmith-Retry-Time",
	maxRetryTimeInSecs: 5,
	initialRetryTimeInSecs: 0.1,
	factor: 0.2,
	multiplier: 2,
	retries: 5,
	validateRetry: (response: Response): boolean =>
		response.responseStatus >= 500,
};

const TokenAdderMiddleware: Middleware = () => ({
	request: async (request: Request): Promise<Request> => {
		try {
			return Auth.currentSession().then((session) => {
				const accessToken = session.getAccessToken();
				const jwtToken = accessToken.getJwtToken();
				return request.enhance({
					headers: { Authorization: `${jwtToken}` },
				});
			});
		} catch (error) {
			return request;
		}
	},
});

setErrorHandler((response) => {
	if (response.status() >= 400) {
		console.log("Error in API: ", response);

		// logger.error('Error in Scenario API', {
		//   statusCode: response.responseStatus,
		//   error: response.error()?.message,
		// });
	}
	return false;
});

export default forge({
	host: process.env.REACT_APP_API_HOST || "http://localhost:3000",
	middleware: [TokenAdderMiddleware, EncodeJson, Retry(retryConfigs)],
	resources: {
		Scenarios: {
			get: {
				path: "/liq/{id}",
				headers: sharedHeaders,
			},
			create: { path: "/liq", method: "post", headers: sharedHeaders },
			update: { path: "/liq/{id}", method: "put", headers: sharedHeaders },
			delete: { path: "/{id}", method: "delete", headers: sharedHeaders },
			share: {
				path: "/share/{id}",
				method: "post",
				headers: sharedHeaders,
				timeout: 30000,
			},
		},
	},
});
